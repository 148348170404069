(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("lodash"), require("coreUtilsLib"), require("prop-types"), require("react"), require("reactDOM"), require("skinUtils"), require("image-client-api"));
	else if(typeof define === 'function' && define.amd)
		define(["lodash", "coreUtilsLib", "prop-types", "react", "reactDOM", "skinUtils", "image-client-api"], factory);
	else {
		var a = typeof exports === 'object' ? factory(require("lodash"), require("coreUtilsLib"), require("prop-types"), require("react"), require("reactDOM"), require("skinUtils"), require("image-client-api")) : factory(root["lodash"], root["coreUtilsLib"], root["prop-types"], root["react"], root["reactDOM"], root["skinUtils"], root["image-client-api"]);
		for(var i in a) (typeof exports === 'object' ? exports : root)[i] = a[i];
	}
})(this, function(__WEBPACK_EXTERNAL_MODULE__0__, __WEBPACK_EXTERNAL_MODULE__2__, __WEBPACK_EXTERNAL_MODULE__3__, __WEBPACK_EXTERNAL_MODULE__4__, __WEBPACK_EXTERNAL_MODULE__8__, __WEBPACK_EXTERNAL_MODULE__18__, __WEBPACK_EXTERNAL_MODULE__20__) {
return 